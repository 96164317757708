import React from 'react';
import Cover from 'react-video-cover';

const videoTwo = 'https://tosesu.com/wp-content/videos/drops.mp4';
// import "./styles.css";

const style = {
  width: '100%',
  height: '100vh',
  position: '',
  top: 0,
  left: 0,
  zIndex: -1,
};

function BgVideoTestimonial() {
  const videoOptions = {
    src: videoTwo,
    autoPlay: true,
    muted: true,
    loop: true,
    playsInline: true,
  };

  return (
    <div style={style}>
      <Cover videoOptions={videoOptions} remeasureOnWindowResize />
    </div>
  );
}

export default BgVideoTestimonial;
